import { DataCenterIds } from '@sm/locale/src/dataCenters';
import USER_EVENTS, { USER_SUBSCRIPTION_STATUS } from './enums';

type BaseEventData = {
  actionType?: string;
  actionFlow?: string;
};
type DigitalDataPageInfo = {
  pageId: string;
  attributes: Record<string, any>;
  pathname: string;
};
type DigitalDataPage = {
  pageInfo: DigitalDataPageInfo;
};

export type DigitalDataEvent = {
  name: USER_EVENTS;
  data: Record<string, any> & BaseEventData;
};

type DigitalDataUser = {
  ep201: string;
  ep202: string;
  id: string;
};

export type DigitalData = {
  page: DigitalDataPage;
  events: DigitalDataEvent[];
  components: Record<string, any>[];
  user: DigitalDataUser;
};

export type EnvironmentDetails = {
  isBrowser: boolean;
};

export type UserSession = {
  /**
   * Whether the user is being impersonated by an admin
   */
  isAdminImpersonation: Boolean;
};

export type UserGroupMembership = {
  /**
   * ID of the group the user belongs to
   */
  groupId?: string;

  /**
   * Type of user within the user's group
   */
  type?: SMUser.GroupMembershipType;
};

export type UserGroup = {
  membership: UserGroupMembership;
};

export type UserHipaaStatus = {
  /**
   * Whether the user's account has HIPAA status
   */
  isHipaaEnabled: boolean;

  /**
   * Whether the user is subscribed as a sensitive data HIPAA account
   */
  isSensitiveDataAccount: boolean;
};

export type MetricsTrackerConfigurationUserType = {
  /**
   * The user's ID or 1 if no user is logged in
   */
  id: string;

  /**
   * If the current user is logged in
   */
  isAuthenticated: boolean;

  /**
   * Date the user created their accound
   */
  joinedAt?: string;

  /**
   * Date the user subscribed
   */
  subscribedAt?: string;

  /**
   * The user's datacenter country code
   */
  dataCenter?: keyof typeof DataCenterIds;

  /**
   * The user session object
   */
  session?: UserSession;

  /**
   * Information about the user's group membership
   */
  group?: UserGroup;

  /**
   * Information about the user account's HIPAA status
   */
  hipaa?: UserHipaaStatus;
};

/**
 * User config properties that can be updated post-initialization
 */
export type MetricsTrackerConfigurationUserUpdateableType = {
  /**
   * The logged in user's name, null/undefined for anonymous
   */
  username?: string;

  /**
   * The logged in user's email address
   */
  email?: string;

  /**
   * The SVMK ID for the display language
   */
  languageId?: string;

  /**
   * The human readable name of the display language
   */
  languageName?: string;

  /**
   * The ISO-639 language code for the display language
   */
  languageCode?: string;

  /**
   * The user's subscription package ID
   */
  package?: string;

  /**
   * The canonical name of the package as defined by usersvc - Leveraged by webplatform Apps
   * Ex: https://go/code/usersvc/blob/main/usersvc/v3/static/static_package_data/package_1.py#L5
   */
  packageCanonicalName?: string;

  /**
   * The canonical name of the package as defined by usersvc - Leveraged by legacy Apps
   * Ex: https://go/code/usersvc/blob/main/usersvc/v3/static/static_package_data/package_1.py#L5
   */
  packageName?: string;

  /**
   * The status of the current user's subscription
   */
  subscriptionStatus?: USER_SUBSCRIPTION_STATUS;
};

export type MetricsTrackerConfigurationType = {
  user: MetricsTrackerConfigurationUserType &
    MetricsTrackerConfigurationUserUpdateableType;
  dataAnalyticsAPIPath: string | null;
  loggingAPIPath: string | null;
  country: string;
  legacyWeb: string;
  pageId?: string;
  gtmId: string;
  // Amplitude subscriber options
  amplitudeToken?: string;
  overrideOneTrust?: boolean;
  amplitudeDeviceId?: string;
  amplitudeSessionId?: number;
  forceAnonymous?: boolean;
};

export type MetricsTrackerUpdateConfigurationType = {
  legacyWeb: string;
  pageId?: string;
};

export type MetricsSubscriber = (
  digitalDataEvent: DigitalDataEvent,
  metricsTrackerConfig: MetricsTrackerConfigurationType
) => void;

export type MetricsTrackerRoot = {
  _amplitudeSubscriber?: MetricsSubscriber;
  config: MetricsTrackerConfigurationType;
  subscribers: MetricsSubscriber[];
  automaticSubscribers: MetricsSubscriber[];
  digitalData: DigitalData;
};

export type MetricsTrackerType = {
  initialize: (config: MetricsTrackerConfigurationType) => MetricsTrackerRoot;
  getConfig(): MetricsTrackerConfigurationType;
  getDigitalData(): DigitalData;
  getSubscribers: () => ((
    digitalDataEvent: DigitalDataEvent,
    metricsTrackerConfig: MetricsTrackerConfigurationType
  ) => void)[];
  getAutomaticSubscribers: () => ((
    digitalDataEvent: DigitalDataEvent,
    metricsTrackerConfig: MetricsTrackerConfigurationType
  ) => void)[];
  addSubscriber: (subscriber: MetricsSubscriber) => void;
  addAutomaticSubscriber: (subscriber: MetricsSubscriber) => void;
  reset: () => MetricsTrackerRoot;
  update: (config: MetricsTrackerUpdateConfigurationType) => MetricsTrackerRoot;
  updateUserConfig: (
    userConfigUpdate: MetricsTrackerConfigurationUserUpdateableType
  ) => MetricsTrackerRoot;
  track: (event: DigitalDataEvent) => void;
  automaticTrack: (event: DigitalDataEvent) => void;
  getDigitalData: () => DigitalData;
  isInitialized: boolean;
};
export type GTMEventObject = {
  hasGDPRConsent?: boolean;
  userId?: string;
  packageId?: string;
  email?: string;
  countryCode?: string;
};
export type GTMEventData = Record<string, string | number | boolean>;
